import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import "./style.css";
const Katalog = () => {
  const book = useRef(null);
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", disableRightClick);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  return (
    <div className="katalogbody w-screen h-screen flex flex-col items-center justify-center">
      <HTMLFlipBook
        width={550}
        height={733}
        size="stretch"
        minWidth={315}
        maxWidth={1000}
        minHeight={400}
        maxHeight={1533}
        maxShadowOpacity={1.0}
        showCover={false}
        mobileScrollSupport={true}
        onFlip={(e) => setPageNumber(e.data)}
        className="mx-0 my-auto"
        ref={book}
      >
        <img
          src={"/assets/maykatalog/01.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/02.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/03.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/04.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/05.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/06.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/07.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/08.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/09.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
        <img
          src={"/assets/maykatalog/10.jpg"}
          alt="Katalog1"
          className="object-contain"
        />
      </HTMLFlipBook>
      <div className="flex flex-row justify-evenly md:justify-center items-center gap-0 md:gap-5 mx-3 z-50 mb-6">
        <button
          onClick={() => book.current.pageFlip().flipPrev()}
          className="bg-slate-600 select-none p-2 rounded-full"
        >
          <BsChevronLeft className="text-white text-2xl" />
        </button>
        <span className="mx-3 text-center text-sm md:text-base">
          Sayfa {pageNumber + 1} - 10
        </span>
        <button
          onClick={() => book.current.pageFlip().flipNext()}
          className="bg-slate-600 select-none p-2 rounded-full"
        >
          <BsChevronRight className="text-white text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default Katalog;
