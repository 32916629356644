import React, { useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { RotasyonData } from "../constant";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
SwiperCore.use([Autoplay]);

const Rotasyon = ({ toggleOff }) => {
  const swiperRef = useRef(null);
  const swiperSettings = {
    spaceBetween: 20,
    autoplay: { delay: 3000, disableOnInteraction: false },
    loop: true,
    onSwiper: (swiper) => (swiperRef.current = swiper),
    slidesPerView: 3,
  };
  const swiperSettings2 = {
    autoplay: { delay: 3000, disableOnInteraction: false },
    loop: true,
    slidesPerView: 1,
  };
  const [checkid, setCheckId] = useState(1);
  const handleItemClick = (index) => {
    setCheckId(index);
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
      swiperRef.current.allowTouchMove = false;
      swiperRef.current.slideToLoop(index, 1000);
    }
  };
  return (
    <div className="w-full h-full p-2 bg-purple-100 rounded-l-lg ">
      <div className="flex">
        <div className="flex flex-col items-center justify-start">
          <div className="w-full ml-3 ">
            <div className="w-12 h-12 ">
              <button
                type="button"
                onClick={toggleOff}
                className="bg-blue-600/40 p-2 rounded-xl"
              >
                <FaArrowRight size={28} color="blue" />
              </button>
            </div>
          </div>
          <div className="h-full py-1 px-12">
            <ul className="h-full p-2 space-y-3 text-lg font-semibold text-nowrap">
              {RotasyonData?.map((item, index) => {
                return (
                  <li
                    key={item.id}
                    className={`cursor-pointer hover:underline underline-offset-2 ${
                      checkid === index ? "underline text-purple-800" : ""
                    }`}
                    onClick={() => handleItemClick(index)}
                  >
                    {item.name}
                  </li>
                );
              })}
              <li>
                <a href="#iletisim">
                  <button className="relative text-white font-semibold py-3 px-6 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105">
                    Satın Al
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full p-3">
          <div className="w-full ">
            <PhotoProvider
              pullClosable={false}
              photoClosable={true}
              maskClosable={false}
              bannerVisible={false}
            >
              <Swiper {...swiperSettings}>
                {RotasyonData?.map((item) => {
                  return (
                    <SwiperSlide key={item.id} className="w-full h-full ">
                      <div className="w-full h-full flex flex-col items-center justify-start">
                        <div className="w-full h-full">
                          <Swiper {...swiperSettings2}>
                            {item?.image1 !== null && (
                              <SwiperSlide className="w-full h-full ">
                                <PhotoView src={item?.image1}>
                                  <img
                                    src={item?.image1}
                                    alt={item.name}
                                    className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                  />
                                </PhotoView>
                              </SwiperSlide>
                            )}
                            {item?.image2 !== null && (
                              <SwiperSlide className="w-full h-full ">
                                <PhotoView src={item?.image2}>
                                  <img
                                    src={item?.image2}
                                    alt={item.name}
                                    className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                  />
                                </PhotoView>
                              </SwiperSlide>
                            )}
                            {item?.image3 !== null && (
                              <SwiperSlide className="w-full h-full ">
                                <PhotoView src={item?.image3}>
                                  <img
                                    src={item?.image3}
                                    alt={item.name}
                                    className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                  />
                                </PhotoView>
                              </SwiperSlide>
                            )}
                            {item?.image4 !== null && (
                              <SwiperSlide className="w-full h-full ">
                                <PhotoView src={item?.image4}>
                                  <img
                                    src={item?.image4}
                                    alt={item.name}
                                    className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                  />
                                </PhotoView>
                              </SwiperSlide>
                            )}
                            {item?.image5 !== null && (
                              <SwiperSlide className="w-full h-full ">
                                <PhotoView src={item?.image5}>
                                  <img
                                    src={item?.image5}
                                    alt={item.name}
                                    className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                  />
                                </PhotoView>
                              </SwiperSlide>
                            )}
                          </Swiper>
                        </div>
                        <div className="text-2xl mt-3 font-semibold text-center">
                          {item?.name}
                        </div>
                        <div className="relative scroll-container">
                          <div className="scroll-text">{item.text}</div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </PhotoProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rotasyon;
