"use client";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import React, { useEffect, useRef, useState } from "react";
import Rotasyon from "./comp/Rotasyon";
import RotasyonMobil from "./comp/mobile/RotasyonMobil";
import LedliUrunler from "./comp/LedliUrunler";
import LedliUrunlerMobil from "./comp/mobile/LedliUrunlerMobil";
import KentUrunler from "./comp/KentUrunler";
import KentUrunlerMobil from "./comp/mobile/KentUrunlerMobil";

const Category1 = () => {
  const [navTitle, setNavTitle] = useState("Rotasyon");
  const [navData, setNavData] = useState("Rotasyon");
  const [isCardActive, setIsCardActive] = useState(false);

  const scrollRef = useRef();
  const tl = useRef();

  const toggleOff = () => {
    if (tl.current) tl.current.reversed(true);
  };
  const toggleTimeline = (item) => {
    setNavData(item);

    if (tl.current) tl.current.reversed(!tl.current.reversed());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (scrollRef.current && !event.target.closest(".product-card")) {
        toggleOff();
        setIsCardActive(false);
      }
    };
    const handlePopState = () => {
      setIsCardActive(false);
      toggleOff();
      window.history.pushState(null, null, window.location.pathname);
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handlePopState);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useGSAP(
    () => {
      let mm = gsap.matchMedia();

      mm.add("(min-width: 1050px)", () => {
        // desktop setup code here...
        tl.current = gsap
          .from("#box", {
            x: "100%",
            duration: 1,
            scrub: 2,
          })
          .reverse();
      });
    },
    { scope: scrollRef }
  );

  const renderPage = () => {
    switch (navData) {
      case "Rotasyon":
        return <Rotasyon toggleOff={toggleOff} />;
      case "LedliUrun":
        return <LedliUrunler toggleOff={toggleOff} />;
      case "KentUrun":
        return <KentUrunler toggleOff={toggleOff} />;

      default:
        break;
    }
  };
  const renderMobilePage = () => {
    switch (navData) {
      case "Rotasyon":
        return <RotasyonMobil setIsCardActive={setIsCardActive} />;
      case "LedliUrun":
        return <LedliUrunlerMobil setIsCardActive={setIsCardActive} />;
      case "KentUrun":
        return <KentUrunlerMobil setIsCardActive={setIsCardActive} />;

      default:
        break;
    }
  };
  return (
    <div
      className="w-full h-full flex flex-col items-center justify-start space-y-5 relative mt-6 lg:mt-24 mb-4"
      ref={scrollRef}
    >
      <div
        className={`product-card hidden lg:block w-[86%] mt-12 h-full overflow-hidden absolute top-0 right-0 z-10 `}
        id="box"
      >
        {renderPage()}
      </div>

      <div
        className={`product-card block lg:hidden w-full overflow-hidden h-[70%] bottom-0 left-0 z-10 fixed transition-transform ease-in-out duration-[1150ms] ${
          isCardActive ? "translate-y-0 " : "translate-y-full"
        }`}
      >
        {renderMobilePage()}
      </div>
      <div className="text-4xl font-bold text-white px-3 text-center">
        ÜRÜN KATALOĞUMUZ
      </div>
      <div className="text-sm font-semibold text-white px-12 text-center lg:px-3 pb-4">
        <p>
          May Plastik olarak, rotasyon çözümleriyle ürettiğimiz ledli ürünler ve
          kent mobilyaları, estetik ve dayanıklılığı bir araya getirerek şehir
          yaşamını zenginleştiriyor. Şık aydınlatma alternatifleri ve
          fonksiyonel mobilyalarla modern alanlar yaratıyoruz.
        </p>
      </div>
      <div className="w-full px-6 lg:px-24 ">
        <ul
          className="w-full flex flex-col lg:flex-row items-center justify-center space-y-6 lg:space-y-0 lg:space-x-6"
          id="navbar-full"
        >
          <li
            className={`w-full h-[500px] rounded-2xl bg-cover bg-center transition-all duration-[1500ms] ease-out ${
              navTitle === "Rotasyon" ? "lg:w-[50%]" : "lg:w-[25%]"
            }`}
            style={{ backgroundImage: "url('/assets/image1.jpg')" }}
            onMouseEnter={() => setNavTitle("Rotasyon")}
            onMouseLeave={() => setNavTitle("Rotasyon")}
          >
            <div className="w-full h-[500px] flex items-end justify-start p-4">
              <div className="w-full flex flex-col text-slate-800/90 mb-12">
                <div className="text-xl xl:text-3xl font-bold mb-4">
                  Rotasyon Çözümlerimiz
                </div>
                <div className="text-sm xl:text-base pr-2 xl:pr-12 font-semibold">
                  Rotasyon Çözümlerimiz ile sektördeki yenilikçi yaklaşımımızı
                  benimsiyoruz. Bu teknoloji sayesinde yüksek kaliteli,
                  dayanıklı ve estetik ürünler üreterek müşteri memnuniyetini ön
                  planda tutuyoruz. Rotasyon çözümlerimiz, farklı ihtiyaçlara
                  yönelik özelleştirilebilir tasarımlar sunmaktadır.
                </div>
                <div className="mt-6 hidden lg:block">
                  <button
                    className="relative text-white font-semibold py-3 px-6 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105"
                    onClick={() => {
                      toggleTimeline("Rotasyon");
                    }}
                  >
                    Göster
                  </button>
                </div>
                <div className="mt-6 block lg:hidden">
                  <button
                    className="relative text-white font-semibold py-3 px-6 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105"
                    onClick={() => {
                      setNavData("Rotasyon");
                      setIsCardActive(true);
                    }}
                  >
                    Göster
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li
            className={`w-full h-[500px] rounded-2xl bg-cover bg-center transition-all duration-[1500ms] ease-out ${
              navTitle === "LedliUrun" ? "lg:w-[50%]" : "lg:w-[25%]"
            }`}
            style={{ backgroundImage: "url('/assets/image2.jpg')" }}
            onMouseEnter={() => setNavTitle("LedliUrun")}
            onMouseLeave={() => setNavTitle("Rotasyon")}
          >
            <div className="w-full h-[500px] flex items-end justify-start p-4">
              <div className="w-full flex flex-col text-slate-800/90 mb-12">
                <div className="text-xl xl:text-3xl font-bold mb-4">
                  Ledli Ürünleri
                </div>
                <div className="text-sm xl:text-base pr-2 xl:pr-12 font-semibold">
                  Ledli Ürünlerimiz ile estetik ve enerji verimliliğini bir
                  araya getiriyoruz. Modern tasarımlarımız, mekanlara şıklık
                  katarken, uzun ömürlü aydınlatma çözümleri sunmaktadır.
                  Yenilikçi teknolojimizle, her türlü ihtiyaca yönelik ledli
                  ürün çeşitliliğimizle sektördeki farkımızı ortaya koyuyoruz.
                </div>
                <div className="mt-6 hidden lg:block">
                  <button
                    className="relative text-white font-semibold py-3 px-6 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105"
                    onClick={() => {
                      toggleTimeline("LedliUrun");
                    }}
                  >
                    Göster
                  </button>
                </div>
                <div className="mt-6 block lg:hidden">
                  <button
                    className="relative text-white font-semibold py-3 px-6 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105"
                    onClick={() => {
                      setNavData("LedliUrun");
                      setIsCardActive(true);
                    }}
                  >
                    Göster
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li
            className={`w-full h-[500px] relative rounded-2xl bg-cover bg-center transition-all duration-[1500ms] ease-out ${
              navTitle === "KentUrun" ? "lg:w-[50%]" : "lg:w-[25%]"
            }`}
            style={{
              backgroundImage: "url('/assets/image3.jpg')",
            }}
            onMouseEnter={() => setNavTitle("KentUrun")}
            onMouseLeave={() => setNavTitle("Rotasyon")}
          >
            <div className="w-full h-[500px] flex items-end justify-start p-4">
              <div className="w-full flex flex-col text-slate-900/90 mb-12">
                <div className="text-xl xl:text-3xl font-bold mb-4">
                  Kent Mobilyaları
                </div>
                <div className="text-sm xl:text-base pr-2 xl:pr-12 font-semibold">
                  May Plastik ile Kent Mobilyaları alanında estetik ve
                  işlevselliği bir araya getiriyoruz. Ürünlerimiz, kamu
                  alanlarını güzelleştirirken, dayanıklılık ve konfor
                  sunmaktadır. Şehir yaşamına değer katan oturma bankları, çocuk
                  oyun parkları ve dekoratif unsurlarla, modern ve
                  sürdürülebilir yaşam alanları yaratıyoruz.
                </div>
                <div className="mt-6 hidden lg:block">
                  <button
                    className="relative text-white font-semibold py-3 px-6 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105"
                    onClick={() => {
                      toggleTimeline("KentUrun");
                    }}
                  >
                    Göster
                  </button>
                </div>
                <div className="mt-6 block lg:hidden">
                  <button
                    className="relative text-white font-semibold py-3 px-6 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105"
                    onClick={() => {
                      setNavData("KentUrun");
                      setIsCardActive(true);
                    }}
                  >
                    Göster
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Category1;
