export const LedliUrunData = [
  {
    id: 1,
    name: "Bistro",
    text: "Dış mekan kullanımına uygun dayanıklı plastik mobilyalar sunarak estetik ve fonksiyonelliği bir araya getirir.",
    image1: "/assets/category/bistro.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 2,
    name: "Küre",
    text: "Hafif yapıları sayesinde dekoratif ve fonksiyonel amaçlar için kolayca kullanılabilir.",
    image1: "/assets/category/kureler.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 3,
    name: "Oturma Grubu",
    text: "Geceleyin estetik bir aydınlatma sağlarken konforlu bir oturum alanı sunar.",
    image1: "/assets/category/oturmagrubu.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 4,
    name: "Gelin Yolu",
    text: "Etkinlik alanlarını süsleyen plastik şeklindeki yapılarla şık bir yürüyüş yolu oluşturur.",
    image1: "/assets/category/gelinyolu.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 5,
    name: "Abajur",
    text: "Mekanlara sıcak bir ambiyans katarken şıklığı da artırır.",
    image1: "/assets/category/abajur.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 6,
    name: "Bar Masası",
    text: "Sosyal alanlarda keyifli sohbetler için ideal bir buluşma noktası sunar.",
    image1: "/assets/category/barmasasi.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 7,
    name: "DJ Kabini",
    text: "Müzik performanslarının kalbini oluşturarak etkinliklere enerjik bir atmosfer katar.",
    image1: "/assets/category/djkabini.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 8,
    name: "Küp",
    text: "Dekoratif bir atmosfer yaratırken farklı mekanlarda aydınlatma ve oturma alanı olarak kullanılır.",
    image1: "/assets/category/kupler.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 9,
    name: "Saksı",
    text: "Bitkileri sergilerken mekanlara sıcak bir ambiyans katmaktadır.",
    image1: "/assets/category/saksi.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 10,
    name: "Sehpalar",
    text: "Oturma alanlarına şıklık katarken günlük kullanım için pratik bir yüzey sunar.",
    image1: "/assets/category/sehpalar.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 11,
    name: "Kilitli Taş",
    text: "Dayanıklı yapılarıyla dış mekanlarda estetik ve işlevsel zemin çözümleri sunar.",
    image1: "/assets/category/kilitlitas.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
];

export const RotasyonData = [
  {
    id: 1,
    name: "Su Depoları",
    text: "Su depoları yüksek dayanıklılık ve uzun ömürlüdür.",
    image1: "/assets/rotasyon/sudeposu1.png",
    image2: "/assets/rotasyon/sudeposu2.png",
    image3: "/assets/rotasyon/sudeposu3.png",
    image4: "/assets/rotasyon/sudeposu4.png",
    image5: null,
  },
  {
    id: 2,
    name: "Hayvancılık Ekipmanları",
    text: "Hayvancılık ekipmanları dayanıklı ve uzun süre kullanıma uygundur.",
    image1: "/assets/rotasyon/hayvan1.jpg",
    image2: "/assets/rotasyon/hayvan2.jpg",
    image3: "/assets/rotasyon/hayvan3.jpg",
    image4: "/assets/rotasyon/hayvan4.jpg",
    image5: null,
  },
  {
    id: 3,
    name: "Fason Üretimlerimiz",
    text: "Çeşitli sektörlere yüksek kaliteli plastik ürünlerin fason üretimi sunulmaktadır.",
    image1: "/assets/rotasyon/fason1.jpg",
    image2: "/assets/rotasyon/fason2.jpg",
    image3: "/assets/rotasyon/fason3.jpg",
    image4: null,
    image5: null,
  },
  {
    id: 4,
    name: "Rotasyon Kalıpları",
    text: "Büyük ve karmaşık plastik parçaların yüksek dayanıklılıkla üretilmesini sağlar.",
    image1: "/assets/rotasyon/rotasyonkalip1.jpg",
    image2: "/assets/rotasyon/rotasyonkalip2.jpg",
    image3: "/assets/rotasyon/rotasyonkalip3.jpg",
    image4: null,
    image5: null,
  },
  {
    id: 5,
    name: "Rotasyon Makineleri",
    text: "plastik ürünlerin homojen bir şekilde şekillendirilmesini ve yüksek verimlilikle üretilmesini sağlar.",
    image1: "/assets/rotasyon/rotasyonkalip3.jpg",
    image2: "/assets/rotasyon/fason2.jpg",
    image3: "/assets/rotasyon/fason3.jpg",
    image4: null,
    image5: null,
  },
  {
    id: 6,
    name: "Zirai İlaçlama Depoları",
    text: "Zirai ilaçlama depoları güvenilir ve kullanışlıdır.",
    image1: "/assets/rotasyon/ilacdepo1.jpg",
    image2: "/assets/rotasyon/ilacdepo2.jpg",
    image3: "/assets/rotasyon/ilacdepo3.jpg",
    image4: "/assets/rotasyon/ilacdepo4.jpg",
    image5: "/assets/rotasyon/ilacdepo5.jpg",
  },
];

export const KentMobilyaData = [
  {
    id: 1,
    name: "Işık Banklar",
    text: "Işıklı bankları, enerji verimliliği sağlarken aydınlatma alanında estetik çözümler sunar.",
    image1: "/assets/kentmobilya/isikbank1.jpg",
    image2: "/assets/kentmobilya/isikbank2.jpg",
    image3: "/assets/kentmobilya/isikbank3.jpg",
    image4: null,
    image5: null,
  },
  {
    id: 2,
    name: "Işıklı Saksılar",
    text: "Işıklı saksılar, bitkileri estetik bir şekilde sergilerken gece aydınlatma işlevi de sağlar.",
    image1: "/assets/kentmobilya/isiksaksi1.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 3,
    name: "Asılabilir Saksı",
    text: "Asılabilir saksılar, mekanlarda yer tasarrufu sağlarken bitkilere şık bir görünüm kazandırır.",
    image1: "/assets/kentmobilya/isiksaksi1.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
  {
    id: 4,
    name: "Modüler Saksı",
    text: "Farklı kombinasyonlarla kişisel alanları renklendirirken bitki yerleştirme esnekliği sunar.",
    image1: "/assets/kentmobilya/isiksaksi1.jpg",
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  },
];
