import React from "react";

import { FaInstagram } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import Category1 from "./Category1";

const HomePage = () => {
  return (
    <div className="w-full h-full overflow-hidden relative bg-[#24003E] pb-12">
      <div className="w-full h-full md:h-[60%] flex flex-col md:grid md:grid-cols-2 ">
        <div className="w-full flex flex-col pt-12 items-start justify-start ">
          <div className="w-full flex flex-row items-center justify-between px-3 md:px-24 ">
            <div className="w-fit h-fit">
              <a href="/">
                <img
                  src="/assets/logo.png"
                  alt="Image1"
                  className="w-40 md:w-72 h-full object-contain"
                />
              </a>
            </div>
          </div>
          <div className="pt-12 md:pt-4 lg:pt-20  pl-4 md:pl-24 flex flex-col ">
            <h1 class="sr-only">
              May Plastik: Rotasyon Teknolojisi ile Kent Mobilyaları ve Ledli
              Ürünler Üretimi
            </h1>
            <h2 className="w-full text-4xl concert text-center md:text-left md:text-6xl lg:text-8xl font-bold text-slate-100 md:leading-[60px] lg:leading-[130px]">
              <span className="md:block">MAY </span>
              <span className="md:block">PLASTİK </span>
            </h2>

            <div className="w-full py-4 mb-4 md:mb-0">
              <div className="text-slate-200 pr-4 md:w-60 lg:w-[550px]">
                May Plastik olarak, rotasyon çözümleriyle ürettiğimiz ledli
                ürünler, kent mobilyaları, su depoları ve hayvancılık
                ekipmanlarıyla kaliteli çözümler sunmaktayız. Abajur, ledli küp
                ve küreler, gelin yolu ve çocuk oyun parkları gibi geniş bir
                ürün yelpazesiyle hizmet veriyoruz. Dayanıklılık ve estetik
                anlayışımızla sektörün lider firmalarından biri olmanın gururunu
                taşıyoruz.
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center bg-[#EEAD53] p-8 md:p-16 lg:p-32 md:rounded-bl-[130px]">
          <div className="w-fit h-fit bg-white rounded-full p-6">
            <img
              src="/assets/maylogogif.gif"
              alt="Image1"
              className="w-[300px] lg:w-[500px] h-full object-contain"
            />
          </div>
        </div>
      </div>

      {/* ------ KATEGORY -------- */}

      <Category1 />

      <div
        className="w-full border-t-[0.3px] border-slate-300/30 py-12"
        id="iletisim"
      >
        <div className="flex flex-col space-y-10 lg:space-y-0 lg:grid lg:grid-cols-12">
          <div className="col-span-4 w-full 2xl:pl-28 flex items-center justify-center px-2">
            <table className=" text-slate-200 text-xl border-separate border-spacing-y-2">
              <tbody>
                <tr className="py-6">
                  <td className="pr-8 text-right text-nowrap">
                    <strong>Adres :</strong>
                  </td>
                  <td>
                    Tatlıcak Mh. Gürçınar Sk. No:60 Vatan San. Sit.
                    Karatay/Konya
                  </td>
                </tr>
                <tr className="py-6">
                  <td className="pr-8 text-right text-nowrap">
                    <strong>Mail :</strong>
                  </td>
                  <td>bilgi@mayplastik.com</td>
                </tr>
                <tr className="py-6">
                  <td className="pr-8 text-right text-nowrap">
                    <strong>Telefon :</strong>
                  </td>
                  <td>+90 544 942 42 82</td>
                </tr>
                <tr className="py-6">
                  <td className="pr-8 text-right text-nowrap">
                    <strong>Sipariş :</strong>
                  </td>
                  <td>+90 332 342 16 66</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-span-4 w-full h-full flex flex-col items-center justify-start space-y-8">
            <div className="w-full flex items-center justify-center">
              <img
                src="/assets/logo.png"
                alt="Image3"
                className="w-60 md:w-80 object-contain"
              />
            </div>
            <div className="flex items-center justify-center space-x-2">
              <a href="https://www.instagram.com/mayplastik/?hl=en">
                <FaInstagram size={34} color="white" />
              </a>

              <a href="https://www.facebook.com/p/Mayplastik-Led-I%C5%9F%C4%B1kl%C4%B1-%C3%9Cr%C3%BCnler-100063619403794/?locale=tr_TR&paipv=0&eav=AfbG8YXh1fYC_rq92rlJiObY2FrYFqQ5-FCHfiT1Zvgmw7Q0nHuubc_Pkkyp6FRZh5c&_rdr">
                <FaFacebookSquare size={34} color="white" />
              </a>

              <a href="https://www.youtube.com/channel/UCaLNZGXcyfR-AqEuYngXIGw">
                <FaYoutubeSquare size={34} color="white" />
              </a>
            </div>
          </div>
          <div className="col-span-4 w-full flex items-center justify-start">
            <div className="w-full h-full pt-8">
              <p className="text-slate-200 text-2xl text-center">
                MAYPLASTİK -{" "}
                <strong className="text-lg">
                  Aracı Değil, İmalatçı Firmayız...
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:ml-28 flex items-center justify-center lg:justify-start">
        <span className="text-white ">
          ©{new Date().getFullYear()}
          <a
            href="https://aydtanitim.com"
            target="_blank"
            rel="noreferrer"
            className="font-semibold  text-white mx-1"
          >
            AYD TANITIM
          </a>
        </span>
      </div>
    </div>
  );
};

export default HomePage;
