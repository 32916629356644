import React, { useRef } from "react";

import { KentMobilyaData } from "../../constant";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/swiper-bundle.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { FaArrowDown } from "react-icons/fa6";
SwiperCore.use([Autoplay, Navigation]);

const KentUrunlerMobil = ({ setIsCardActive }) => {
  const swiperRef = useRef(null);
  const swiperSettings = {
    spaceBetween: 20,
    autoplay: { delay: 3000, disableOnInteraction: false },
    loop: true,
    onSwiper: (swiper) => (swiperRef.current = swiper),
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  const swiperSettings2 = {
    autoplay: { delay: 2000, disableOnInteraction: false },
    loop: true,
    slidesPerView: 1,
  };
  const handleItemClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
      swiperRef.current.allowTouchMove = false;
      swiperRef.current.slideToLoop(index, 1000);
    }
  };
  return (
    <div className="w-full h-full p-2 bg-purple-100  ">
      <div className="flex flex-col">
        <div className="flex flex-col items-center justify-start pt-2">
          <div className="w-full flex items-center justify-between px-3 ">
            <div className="w-12 h-12 ">
              <button
                type="button"
                onClick={() => setIsCardActive(false)}
                className="bg-blue-600/40 p-2 rounded-xl"
              >
                <FaArrowDown size={24} color="blue" />
              </button>
            </div>
            <div>
              <a
                href={`https://wa.me/905449424282?text=Kent%20Mobilyalar%C4%B1%20i%C3%A7in%20bilgi%20almak%20istiyorum`}
              >
                <button className="relative text-white font-semibold py-2 px-6 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105">
                  Satın Al
                </button>
              </a>
            </div>
          </div>
          <div className="w-full pt-2">
            <ul className="w-full h-full flex p-2 space-x-4 text-base font-semibold text-nowrap overflow-x-scroll">
              {KentMobilyaData?.map((item, index) => (
                <li key={index}>
                  <button
                    className="relative text-white font-semibold py-2 px-4 rounded-lg shadow-md bg-[#24003E] hover:bg-[#F5AE3F] focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all duration-500 ease-in-out hover:scale-105"
                    onClick={() => handleItemClick(index)}
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="w-full p-3">
          <PhotoProvider
            pullClosable={false}
            photoClosable={true}
            maskClosable={false}
            bannerVisible={true}
          >
            <Swiper {...swiperSettings}>
              {KentMobilyaData?.map((item) => {
                return (
                  <SwiperSlide key={item.id} className="w-full h-full ">
                    <div className="w-full h-full flex flex-col items-center justify-center">
                      <div className="w-full h-full">
                        <Swiper {...swiperSettings2}>
                          {item?.image1 !== null && (
                            <SwiperSlide className="w-full h-full ">
                              <PhotoView src={item?.image1}>
                                <img
                                  src={item?.image1}
                                  alt={item.name}
                                  className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                />
                              </PhotoView>
                            </SwiperSlide>
                          )}
                          {item?.image2 !== null && (
                            <SwiperSlide className="w-full h-full ">
                              <PhotoView src={item?.image2}>
                                <img
                                  src={item?.image2}
                                  alt={item.name}
                                  className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                />
                              </PhotoView>
                            </SwiperSlide>
                          )}
                          {item?.image3 !== null && (
                            <SwiperSlide className="w-full h-full ">
                              <PhotoView src={item?.image3}>
                                <img
                                  src={item?.image3}
                                  alt={item.name}
                                  className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                />
                              </PhotoView>
                            </SwiperSlide>
                          )}
                          {item?.image4 !== null && (
                            <SwiperSlide className="w-full h-full ">
                              <PhotoView src={item?.image4}>
                                <img
                                  src={item?.image4}
                                  alt={item.name}
                                  className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                />
                              </PhotoView>
                            </SwiperSlide>
                          )}
                          {item?.image5 !== null && (
                            <SwiperSlide className="w-full h-full ">
                              <PhotoView src={item?.image5}>
                                <img
                                  src={item?.image5}
                                  alt={item.name}
                                  className="w-full h-full object-contain object-center  border-2 border-slate-400"
                                />
                              </PhotoView>
                            </SwiperSlide>
                          )}
                        </Swiper>
                      </div>
                      <div className="text-2xl mt-3 font-semibold text-center">
                        {item?.name}
                      </div>
                      <div className="relative scroll-container">
                        <div className="text-sm scroll-text">{item.text}</div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </PhotoProvider>
          <div className="swiper-button-prev cursor-pointer absolute left-0 top-1/2 z-20"></div>
          <div className="swiper-button-next cursor-pointer absolute right-0 top-1/2 z-20"></div>
        </div>
      </div>
    </div>
  );
};

export default KentUrunlerMobil;
